import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CardActions, Paper } from "@mui/material";

export const Article = ({
  id,
  header,
  children,
  footer,
}: {
  id?: string;
  header: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  const [elevation, setElevation] = useState(0);
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    controls.start(inView ? "visible" : "hidden").then(() => {
      /*pass*/
    });
  }, [controls, inView]);
  return (
    <motion.div
      id={id}
      ref={ref}
      animate={controls}
      transition={{
        duration: 0.5,
      }}
      initial="hidden"
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
    >
      <Paper
        className="article"
        elevation={elevation}
        onMouseOver={() => setElevation(24)}
        onMouseLeave={() => setElevation(0)}
      >
        {header}
        {children}
        <CardActions>{footer}</CardActions>
      </Paper>
    </motion.div>
  );
};
