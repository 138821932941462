import React from "react";
import { Box, Typography } from "@mui/material";

export const ArticleHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <Typography variant="h6" className={className}>
    <Box display="flex" justifyContent="center" alignItems="center">
      {children}
    </Box>
  </Typography>
);
