import { Favorite } from "@mui/icons-material";
import React from "react";

export const Love = ({
  fontSize = "medium",
  color="secondary",
}: {
  fontSize?: "small" | "inherit" | "medium" | "large" | undefined;
  color?: "inherit" | "disabled" | "action" | "secondary" | "primary" | "error";
}) => {
  return <Favorite fontSize={fontSize} color={color} className="m-l m-r"/>;
};
