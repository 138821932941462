import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import theme from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { inject } from "@vercel/analytics";

inject();

let container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <App />
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4431336364368645"
            crossOrigin="anonymous"
          ></script>
        </CssBaseline>
      </ThemeProvider>
    </React.StrictMode>
  );
}
