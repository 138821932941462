import { MoodOutlined } from "@mui/icons-material";
import React from "react";

export const Smiling = ({
  fontSize = "small",
  color = "action",
}: {
  fontSize?: "small" | "inherit" | "medium" | "large" | undefined;
  color?: "inherit" | "disabled" | "action" | "secondary" | "primary" | "error";
}) => {
  return <MoodOutlined fontSize={fontSize} color={color} className="m-l m-r" />;
};
