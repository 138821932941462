import React from "react";
import logo from "./assets/logo.svg";
import "./App.css";
import { Box, Button, Container, Grid, List, Typography } from "@mui/material";
import {
  Article,
  ListItem,
  Love,
  Smiling,
  GradientButton,
  ArticleHeader,
  ArticleFooter,
} from "./components";
import { motion, useMotionValue, useTransform } from "framer-motion";

const WeDo = () => (
  <Article
    id="wedo"
    header={
      <ArticleHeader>
        We
        <Love />
        to do
      </ArticleHeader>
    }
  >
    <List>
      <ListItem text="Software development" />
      <ListItem text="Software architecture" />
      <ListItem text="Analysis" />
      <ListItem text="Audit" />
      <ListItem text="Coaching" />
      <ListItem text="Training" />
    </List>
  </Article>
);

const UsingDev = () => (
  <Article
    id="using-dev"
    header={
      <ArticleHeader>
        We
        <Love />
        to develop with
      </ArticleHeader>
    }
    footer={
      <ArticleFooter>
        But not only
        <Smiling />
      </ArticleFooter>
    }
  >
    <List>
      <ListItem text="React" />
      <ListItem text="Node.js" />
      <ListItem text="Python" />
    </List>
  </Article>
);

const UsingProducts = () => (
  <Article
    id="using-products"
    header={
      <ArticleHeader>
        We
        <Love />
        to use
      </ArticleHeader>
    }
  >
    <List>
      <ListItem text="Linux OS" />
      <ListItem text="Arista switches" />
      <ListItem text="Cisco switches" />
      <ListItem text="Prometheus" />
      <ListItem text="Grafana" />
    </List>
  </Article>
);

const Interests = () => (
  <Article
    id="interests"
    header={
      <ArticleHeader className="gravity-font">
        We also
        <Love />
      </ArticleHeader>
    }
  >
    <List>
      <ListItem text="Agile" />
      <ListItem text="AMWA NMOS" />
      <ListItem text="Docker" />
      <ListItem text="Yocto" />
    </List>
  </Article>
);

const Collab = () => (
  <Article
    id="collab"
    header={
      <ArticleHeader className="gravity-font">
        We also
        <Love />
        to work with
      </ArticleHeader>
    }
  >
    <List>
      <ListItem text="EVS" />
      <ListItem text="EMG" />
      <ListItem text="UserFriendly" />
    </List>
  </Article>
);

function TrackingLogo() {
  const x = useMotionValue(window.innerWidth / 2);
  const y = useMotionValue(window.innerHeight / 2);
  const rotateX = useTransform(y, [0, window.innerHeight], [15, -15]);
  const rotateY = useTransform(x, [0, window.innerWidth], [-15, 15]);
  const handleMouseMove = React.useCallback((event: any) => {
    const rect = event.currentTarget.getBoundingClientRect();
    x.set(event.clientX - rect.left);
    y.set(event.clientY - rect.top);
  }, []);
  return (
    <div
      onMouseMove={handleMouseMove}
      style={{ width: "100%", height: "100vh", perspective: 400 }}
    >
      <motion.img
        style={{ rotateX, rotateY }}
        src={logo}
        className="App-logo"
        alt="Gravity software srl"
      />
    </div>
  );
}

const App = () => (
  <div className="App">
    <header className="Page App-header">
      <TrackingLogo />
    </header>
    <main className="App-main">
      <Container fixed>
        <Grid container spacing={3} className="content">
          <Grid item xs={12}>
            <Box m={5}>
              <GradientButton href="#contact">Contact us</GradientButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <WeDo />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UsingDev />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UsingProducts />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Interests />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Collab />
          </Grid>
        </Grid>
      </Container>
    </main>
    <footer className="App-footer">
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Article
              id="contact"
              header={<Typography variant="h5">Contact us</Typography>}
            >
              <Typography variant="body1">
                Rue Saint-Sauveur, 288C
                <br />
                4520, Moha
                <br />
                Belgium
                <br />
                TVA BE 0770.547.709
                <br />
              </Typography>
              <Button href="mailto:info@gravitysoftware.be">
                info@gravitysoftware.be
              </Button>
            </Article>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <Typography variant="caption">
                © {new Date().getFullYear()} Gravity software srl
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  </div>
);

export default App;
